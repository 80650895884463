import React from 'react';
import Footer from './Footer';
import axios from 'axios';

class Refund extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      description1: null,
      description2: null,
      description3: null
    }
    this.getRefundPolicy = this.getRefundPolicy.bind(this);
  }
  getRefundPolicy(){
    const api = process.env.REACT_APP_API_KEY;
    const responseHeader = {
      query: "getRefundPolicy",
      data: {}
    }
    const data = axios.post(api, responseHeader).then(response =>{
      return response.data
    });
    data.then(value=>{
      this.setState({description1: value.data[0].refundpolicy});
      this.setState({description2: value.data[1].refundpolicy});
      this.setState({description3: value.data[2].refundpolicy});
    })
  }

  componentDidMount(){
    this.getRefundPolicy();
  }

  render() {
    return (
      <div className='Content'>
        <div className='Store'>
        <div className='Refund'>
                <h2>Return, Refund & Cancellation Policy</h2>
            <div className=' Refund-form'>
              <div className="form-control">
                <p dangerouslySetInnerHTML={{ __html: this.state.description1}}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: this.state.description2}}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: this.state.description3}}>
                </p>
              </div>
            </div>
        </div>
        <Footer/>
        </div>
      </div>
    )
  }
}

export default Refund