import React from 'react';
import axios from 'axios';
import { GoogleOAuthProvider } from '@react-oauth/google';

class Profile extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loginData: {
        phone: "",
        password: ""
      },
      showForgetPassword: true,
    }

    this.handlePhone = this.handlePhone.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPopupDataChange = this.onPopupDataChange.bind(this);

  }

  handlePhone(e){
    let loginData = this.state.loginData;
    loginData.phone = e.target.value;
    this.setState({loginData: loginData})
  }

  handlePassword(e){
    let loginData = this.state.loginData;
    loginData.password = e.target.value;
    this.setState({loginData: loginData})
  }

  handleSubmit(){
    const loginDetails = axios.post( this.props.api ,{
      query : "login",
      data : { 
        phone: this.state.loginData.phone,
        password: this.state.loginData.password 
      }
    }).then(response =>{
      return response.data
    })
    loginDetails.then( value =>{
      this.props.loginMessage(value);
      if(value.Message === "Login success."){
        this.props.onClose();
      }
    });
  }

  onPopupDataChange(){
    this.setState({showForgetPassword: !this.state.showForgetPassword})
  }

  render() {
    const popupData = this.state.showForgetPassword ? 
    <Login loginData={this.state.loginData} handlePhone={this.handlePhone} handlePassword={this.handlePassword} 
    handleSubmit={this.handleSubmit} onPopupDataChange={this.onPopupDataChange}/> : 
    <ForgotPassword onPopupDataChange={this.onPopupDataChange}/>

    return (
        <div className='popup'>
          <button className='Button-Close' onClick={this.props.onClose}>X</button>
          <div className='popup_inner'>
            <GoogleOAuthProvider clientId="409955356430-bfvalraas6c9hledgfo0mekmckaupsaq.apps.googleusercontent.com">
            <div className='Profile'>
              {popupData}
            </div>
            </GoogleOAuthProvider>
        </div>
      </div>
    )
  }
}

export default Profile

class Login extends React.Component {
  render() {
    return (
            <>
              <div className='Profile-Heading'>
                <h3>LOGIN</h3>
              </div>
              <div className='Profile-Form'>
                  <div className="Profile-Form-Control">
                    <input type="number" class="Phone" placeholder='Enter Mobile Number' 
                    value={this.props.loginData.phone} onChange={this.props.handlePhone}/>
                  </div>
                  <div className="Profile-Form-Control">
                    <input type="password" class="Password" placeholder='Enter Password' 
                    value={this.props.loginData.password} onChange={this.props.handlePassword}/>
                  </div>
              </div>
              <div className='Profile-Submit'>
                  <div className="Profile-Form-Control">
                    <button onClick={this.props.handleSubmit}>SUBMIT</button>
                  </div>
                  <div className="Profile-Form-Control">
                    <span onClick={this.props.onPopupDataChange}>Forgot Password ?</span>
                  </div>
              </div>
            </>
    )
  }
}

class ForgotPassword extends React.Component {
  render() {
    return (
            <>
            <div className='Profile-Heading'>
              <h3>RESET PASSWORD</h3>
            </div>
            <div className='Profile-Form'>
                <div className="Profile-Form-Control">
                  <input type="number" class="Phone" placeholder='Enter Mobile Number'/>
                </div>
            </div>
            <div className='Profile-Submit'>
                <div className="Profile-Form-Control">
                  <button>GET OTP</button>
                </div>
                <div className="Profile-Form-Control">
                  <span onClick={this.props.onPopupDataChange}>Already have account Password Login Here !</span>
                </div>
            </div>
            </>
    )
  }
}