import React from 'react';
import SearchProductList from './SearchProductList';
import { IoIosBasket } from 'react-icons/io';
import { GrAdd } from 'react-icons/gr';
import { GrSubtract } from 'react-icons/gr';
import { Outlet, Link } from "react-router-dom";
import Footer from './Footer';

class Cart extends React.Component {
  componentDidMount(){
    const responseHeaders = {
      query: "getActiveCart",
      data: {}
    }
    const jwt = localStorage.getItem('userDetails');
    const authorisation = {
      headers:{
        'Authorisation' : jwt
      }
    }
    this.props.onDataForCart(responseHeaders, authorisation);
  }
  render() {
    const api = this.props.api;
    const searchProductList = this.props.searchProductList || [];
    const favoriteList = this.props.favoriteList || [];
    var cartProductList = this.props.cartList || [];
    var totalprice = cartProductList.map((item)=>{return JSON.parse(item?.totalprice)});
    var discount = cartProductList.map((item)=>{return JSON.parse(item?.discount)});
    var netPay = 0;
    var savings = 0;
    for (let i = 0; i < totalprice.length; i++) {
      netPay += totalprice[i];
    }
    for (let j = 0; j < discount.length; j++) {
      savings += discount[j];
    }
    const subTotal = netPay + savings

    return (
      <div className='Content'>
        <div className='Store'>
        <div className='Category'>
            <SearchProductList api={api} onDataForCart={this.props.onDataForCart} addToFavorites={this.props.addToFavorites}
                addToCart={this.props.addToCart} favoriteList={favoriteList} searchProductList={searchProductList}/>
            <div className='Category-Head'>
                <h2>My Basket</h2>
            </div>
            {
              cartProductList.length === 0 ? 
              <EmptyCart/> : 
              <CartTable cartProductList={cartProductList} subTotal={subTotal} netPay={netPay} 
                         savings={savings} clearCart={this.props.clearCart} addToCart={this.props.addToCart} 
                         addToFavorites={this.props.addToFavorites} deleteCartItem={this.props.deleteCartItem}
                         increaseCartItem={this.props.increaseCartItem} decreaseCartItem={this.props.decreaseCartItem}/>
            }
        </div>
        <Footer/>
        </div>
      </div>
    )
  }
}

export default Cart

class EmptyCart extends React.Component {
  render() {
    return (
      <div className='EmptyCart'>
        <div className='Basket-Icon'><IoIosBasket/></div>
        <h3>Let's fill the empty Basket</h3>
        <Link to={"/"}>
          <button>Continue Shopping</button>
        </Link>
        <Outlet/>
      </div>
    )
  }
}

class CartTable extends React.Component {
  render() {
    const cartProductList = this.props.cartProductList;
    const subTotal = this.props.subTotal;
    const savings = this.props.savings;
    const netpay = this.props.netPay;

    return (
      <div className='CartTable'>
        <div className='Cart-Product-List'>
          <div className='Cart-Product-Heading'>
            <div className='Heading-1'><h4>Items</h4></div>
            <div className='Heading-2'><h4>Quantity</h4></div>
            <div className='Heading-3'><h4>Sub-Total</h4></div>
          </div>
          {cartProductList.map((item, index)=>{
            return  <div className='Cart-Product' key={index}>
                      <div className='Content-1'>
                        <div className='Cart-Product-Image'>
                          <img src={item?.newproductimage === "" ? 
                          "https://www.veekaart.com/assets/img/noimage.png" : 
                          "https://api.veekaart.com/assets/" + JSON.parse(item?.newproductimage)[0]}
                          alt={item?.newproductname}/>
                        </div>
                        <div className='Cart-Product-Details'>
                          <span className='Cart-Product-Name'>{item?.productname} - {item?.newproductname}</span><br/>
                          <span className='Cart-Product-Offer-Price'>{item?.offerperc}%&nbsp;&nbsp;&nbsp;&#8377; {item?.sellingprice}</span><br/>
                          <span className='Cart-Product-Unit'>{item?.unit}</span>
                        </div>
                      </div>
                      <div className='Content-2'>
                        <div className='Cart-Quantity'>
                          <div className='Quantity-Minus' onClick={
                            ()=>{this.props.decreaseCartItem(item?.productid,item?.unit,JSON.parse(item?.qty))}
                          }>
                            <GrSubtract/>
                          </div>
                          <div>{item.qty}</div>
                          <div className='Quantity-Plus' onClick={
                            ()=>{this.props.increaseCartItem(item.productid,item.unit,JSON.parse(item?.qty),JSON.parse(item?.maxorderqty))}}>
                            <GrAdd/>
                          </div>
                        </div>
                        <div className='Cart-Product-Modify'>
                          <span onClick={()=>{this.props.deleteCartItem(item?.productid, item?.unit)}}>
                            Delete
                          </span>
                        </div>
                      </div>
                      <div className='Content-3'>
                        <div className='Cart-SellingPrice'>&#8377;{JSON.parse(item?.qty)*JSON.parse(item?.sellingprice)}</div>
                        <div className='Cart-Discount'>Saved: &#8377;{item?.discount}</div>
                      </div>
                    </div>
          })}
        </div>
        <div className='Cart-Total'>
          <div className='Cart-Count-Savings'>
            <span><b>Sub Total : </b>&#8377;&nbsp;{subTotal}</span>&nbsp;&nbsp;
            <span className='Cart-Savings'><b>Savings : </b>&#8377;&nbsp;{savings}</span><br/>
            <span><b>Net Payable : </b>&#8377;&nbsp;{netpay}</span>
          </div>
          <div className='Cart-Checkout-Buttons'>
            <button onClick={()=>{this.props.clearCart();}}>Clear Basket</button>
            <Link to={'/Checkout'}><button>Checkout</button></Link>
          </div>
        </div>
      </div>
    )
  }
}