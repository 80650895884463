import React from 'react';
import axios from 'axios';
import Footer from './Footer';

class FAQ extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            description: null,
            activeIndex: null,
        }
        this.getFaq = this.getFaq.bind(this);
        this.getActiveIndex = this.getActiveIndex.bind(this);
    }

    getFaq(){
        const api = process.env.REACT_APP_API_KEY;
        const responseHeader = {
            query: "getActiveFaq",
            data: {}
        }
        const data = axios.post(api, responseHeader).then(response =>{
            return response.data
        });
        data.then(value =>{
            this.setState({description: value.data});
            console.log(value.data);
        });
    }

    getActiveIndex(newIndex){
        if(this.state.activeIndex === newIndex){
            this.setState({activeIndex: null})
        }
        else{
            this.setState({activeIndex: newIndex})
        }
    }

    componentDidMount(){
        this.setState({activeIndex: null})
        this.getFaq();
    }

  render() {
    const description = this.state.description || [];
    const activeIndex = this.state.activeIndex;
    return (
        <div className='Content'>
            <div className='Store'>
                <div className='Faq'>
                    <h2>FAQ</h2>
                <div className='Faq-form'>
                    <div className="form-control">
                        {description.map((item,index)=>{
                            return  <div className='Pop-Up' key={index} onClick={()=>{this.getActiveIndex(index)}}>
                                        <p>{item.question}</p>
                                            <div className= {activeIndex === index ? "form-control" : 'form-control-unview'}>
                                                <p>
                                                    {item.answer}
                                                </p>
                                            </div>
                                    </div>
                        })}
                    </div>
                </div>
                </div>
                <Footer/>
            </div>
        </div>
    )
  }
}

export default FAQ