import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

class Register extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      registerCheck: "Individual",
      submitted: false,
      checked: false,
      registerDetails : {
        userName : "",
        userNumber : "",
        userEmail : "",
        userPassword : ""
      }
    }

    this.onIndividual = this.onIndividual.bind(this);
    this.onWholesaler = this.onWholesaler.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleNumber = this.handleNumber.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  onIndividual(){
    this.setState({registerCheck: "Individual"})
  }
  onWholesaler(){
    this.setState({registerCheck: "WholeSaler"})
  }
  handleName(e){
    let registerDetails = this.state.registerDetails
    registerDetails.userName = e.target.value
    this.setState({registerDetails: registerDetails })
  }
  handleNumber(e){
    let registerDetails = this.state.registerDetails
    registerDetails.userNumber = e.target.value
    this.setState({registerDetails: registerDetails })
  }
  handleEmail(e){
    let registerDetails = this.state.registerDetails;
    registerDetails.userEmail = e.target.value
    this.setState({registerDetails: registerDetails})
  }
  handlePassword(e){
    let registerDetails = this.state.registerDetails
    registerDetails.userPassword = e.target.value
    this.setState({registerDetails: registerDetails })
  }
  handleCheckbox(boolean){
    if(boolean){
      this.setState({checked: true});
    }
    if(!boolean){
      this.setState({checked: false});
    }
  }
  handleSubmit(){
    if(this.state.checked){
      const registrationDetails = axios.post( this.props.api,{
        query : "newRegistration",
        data : {
          userName: this.state.registerDetails.userName,
          email: this.state.registerDetails.userEmail,
          phone: this.state.registerDetails.userNumber,
          status: "1",
          password: this.state.registerDetails.userPassword,
          profileimage: "",
          ipaddress: "Http failure response for (unknown url): 0 Unknown Error"
        }
      }).then(response =>{
        return response.data
      }).catch(error => {
        console.log(error);
      })
      registrationDetails.then(value =>{
        console.log(value)
//        this.props.registerMessage(value);
        if(value.data.Level === "Error"){
          toast.error(value.data.Message);
        }
//        if(value.Message === "Registration successfull."){
//          this.props.onClose();
//        }
      });
      console.log(this.state.registerDetails);
    }
    if(!this.state.checked){
      toast.error("Kindly Accept the Terms & Condition");
    }
    if(this.state.registerDetails.userName === '' || 
       this.state.registerDetails.userEmail === '' || 
       this.state.registerDetails.userNumber === '' || 
       this.state.registerDetails.userPassword === ''){
        toast.warn("Please Fill the Required Fields");
       }
  }

  render() {
    const registerCheck = this.state.registerCheck;
    const activeRegister = registerCheck === 'Individual' ? 
                          <Individual handleName={this.handleName} handleNumber={this.handleNumber}
                                      handleEmail={this.handleEmail} handlePassword={this.handlePassword} 
                                      handleSubmit={this.handleSubmit} handleCheckbox={this.handleCheckbox}
                                      registerDetails={this.state.registerDetails}/> : 
                          <Wholesaler/> ;

    return (
      <div className='Popup'>
        <button className='Button-Close-Register' onClick={this.props.onClose}>X</button>
        <div className='Popup_Inner'>
          <div className='Register'>
            <div className="Register-Header">
              <div className='Register-Heading' onClick={this.onIndividual}>
                <h3>Individual</h3>
              </div>
              <div className='Register-Heading' onClick={this.onWholesaler}>
                <h3>Wholesaler</h3>
              </div>
            </div>
              {activeRegister}
          </div>
        </div>
      </div>
    )
  }
}

export default Register

class Individual extends React.Component {
  render() {
    return (
      <div className='Register-Form'>
              <div className="Register-Form-Control">
                  <input type="text" className="Phone" placeholder='Enter your Name *' value={this.props.registerDetails.userName} onChange={this.props.handleName}/>
              </div>
              <div className="Register-Form-Control">
                  <input type="number" className="Phone" placeholder='Enter your Number *' value={this.props.registerDetails.userNumber} onChange={this.props.handleNumber}/>
              </div>
              <div className="Register-Form-Control">
                  <input type="email" className="Phone" placeholder='Enter your Mail Id *' value={this.props.registerDetails.userEmail} onChange={this.props.handleEmail}/>
              </div>
              <div className="Register-Form-Control">
                  <input type="password" className="Password" placeholder='Enter your Password *' value={this.props.registerDetails.userPassword} onChange={this.props.handlePassword}/>
              </div>
              <div className='Register-Form-Control'>
                  <input type="checkbox" className='CheckBox' onChange={(e)=>{this.props.handleCheckbox(e.target.checked);}}/>
                    I accept the <Link to={'/Terms'}>Terms & Condition</Link>
              </div>
              <div className='Register-Submit'>
                <div className="Register-Form-Control">
                    <button onClick={()=>{
                      this.props.handleSubmit();
                    }}>SIGN UP</button>
                </div>
              </div>
        </div>
    )
  }
}

class Wholesaler extends React.Component {
  render() {
    return (
        <div className='Profile-Form'>
          <form>
              <div className="Register-Form-Control">
                <p>Please share your GSTIN to get Wholesaler account with us.</p>
              </div>
              <div className="Register-Form-Control">
                <b>Address:</b>
                <p>RAMNATH TRADERS<br/>#454SE,29th Main, 2nd Cross,<br/>Nisarga Layout,Jigani,<br/>Bangalore-560083.</p>
              </div>
              <div className="Register-Form-Control">
                <p><b>E-Mail:</b>&nbsp;veekaart.onlinestore@gmail.com</p>
                <p><b>Phone:</b>&nbsp;+91 7892853796</p>
              </div>
          </form>
        </div>
    )
  }
}