import axios from 'axios';
import React from 'react';
import { Fragment } from 'react';
import { IoCheckmarkOutline } from 'react-icons/io5';
import ClipLoader from "react-spinners/ClipLoader";
import SearchProductList from './SearchProductList';
import ProductDetail from './ProductDetail';
import Footer from './Footer';

class Product extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            productSelect : null,
            activeIndex : 0,
            productImage : null,
            productQty: 1,
            override : {
                display: "block",
                margin: "26vh auto",
              }
        }
        this.onDataForProductView = this.onDataForProductView.bind(this);
    }

    onDataForProductView(newId, newSubCategoryName){
        this.props.onDataForProductView(newId);
        this.props.onDataForSimilarProducts(newSubCategoryName,newId);
    }

    componentDidMount(){
        const pathname = window.location.pathname.split(';');
        this.props.onDataForProductView(decodeURI(pathname[1]));
        this.props.onDataForSimilarProducts(decodeURI(pathname[2]),decodeURI(pathname[1]));

        const responseHeaders = {
            query: "getActiveCart",
            data: {}
          }
          const jwt = localStorage.getItem('userDetails');
          const authorisation = {
            headers:{
              'Authorisation' : jwt
            }
          }
          this.props.onDataForCart(responseHeaders, authorisation);
    }
  render() {
    const api = this.props.api;
    const searchProductList = this.props.searchProductList || [];
    const productQty = this.state.productQty
    const productView = this.props.productView || [];
    const similarProducts = this.props.similarProducts || [];
    const productSelect = this.state.productSelect || [];
    const activeIndex = this.state.activeIndex;
    const productRate = [productSelect[activeIndex]] || [];
    const image = this.state.productImage || null;
    const Loader = <ClipLoader cssOverride={this.state.override} size={25} color={"#123abc"} loading={true} speedMultiplier={1.5}
                    aria-label="Loading Spinner" data-testid="loader"/>
    const favoriteList = this.props.favoriteList || [];

    return (
      <div className='Content'>
        <div className='Store'>
            <div className='Category'>
                <SearchProductList api={api} onDataForCart={this.props.onDataForCart} addToFavorites={this.props.addToFavorites}
                             addToCart={this.props.addToCart} favoriteList={favoriteList} searchProductList={searchProductList}/>
                <div className='Category-Head'><h2>Product Details</h2></div>
                {
                productView.length === 0 ? Loader : productView.map((item, index)=>{
                 return <div className='Product-View' key={index} onLoad={()=>{
                         axios.post( api ,{
                            query : "getProductunitpriceByProductCode",
                            data : { "productcode" : item.code},
                            }).then( response => {
                                this.setState({productSelect: response.data.data});
                                this.setState({productImage: response.data.data[activeIndex].productimage});
                            })
                            this.setState({productRate: productSelect[activeIndex]})
                        }}>
                            <div className='Product-View-Left'>
                                <img src={image === null ? "https://www.veekaart.com/assets/img/noimage.png" : "https://api.veekaart.com/assets/" + JSON.parse(image)[0]} 
                                alt={item.productname}/>
                            </div>
                            <div className='Product-View-Right'>
                                <div className='Product-View-Details'>
                                    <div className='Product-Brand'>{item.brandname}</div>
                                    <div className='Product-Name'><b>{item.productname}</b></div>
                                    <div>{item.categoryname}</div>
                                </div>
                                <div className='Product-View-Rate-Details'>
                                    {
                                    productRate.map((item, index)=>{
                                        return <Fragment key={index}>
                                                    <div className='Product-View-Rate-Mrp'>MRP: Rs {item?.mrp}</div>
                                                    <div className='Product-View-Rate-Selling-Price'><b>Price: Rs {item?.sellingprice}</b></div>
                                                    <div className='Product-View-Rate-Offer'>You Save: {item?.offer}%</div>
                                                    <div className='Product-View-Rate-Tax'>(Inclusive of all Taxes)</div>
                                                </Fragment>
                                                })
                                    }
                                </div>
                                <div className='Product-View-Quantity'>
                                    <div className='Product-View-Quantity-Select'>
                                        <input type={"number"} defaultValue={1} min={1} max={productRate[0]?.maxorderqty} onChange={
                                            (e)=>{this.setState({productQty: e.target.value})}
                                        }/>
                                    </div>
                                    {
                                        productRate[0]?.stock !== '0' ?
                                        <>
                                        <div className='Product-View-Quantity-Cart'>
                                            <button onClick={()=>{this.props.addToCart(productView[0]?.productid,productQty,productSelect[activeIndex]?.unit)}}>
                                                ADD TO BASKET
                                            </button>
                                        </div>
                                        <div className='Product-View-Quantity-Fav'>
                                            <button onClick={()=>{this.props.addToFavorites(productView[0]?.productid);}}>ADD TO FAVORITE</button>
                                        </div>
                                        </> :
                                        <div className='Product-View-Quantity-Cart'>
                                            <button className='Out-Of-Stock-Button'>OUT OF STOCK</button>
                                        </div>
                                    }
                                </div>
                                <div className='Product-View-Rate'>
                                    <span>Pack Sizes</span>
                                    {
                                    productSelect.map((item, index)=>{
                                        return <Fragment key={index}>
                                                    <div className={activeIndex === index ? "Product-View-Select-active" : "Product-View-Select"} 
                                                    onClick={()=>{
                                                        this.setState({activeIndex: index});
                                                    }}>
                                                        <div className='Product-View-Select-Unit'>
                                                            {item.unit}
                                                        </div>
                                                        <div className='Product-View-Select-Rate'>
                                                            <span className='Selling-Price'>Rs.{item.sellingprice}</span>&nbsp;&nbsp;
                                                            <span className='Mrp-Price'>MRP: Rs.{item.mrp}</span>&nbsp;&nbsp;
                                                            <span className='Offer'>{item.offer}% Off</span>
                                                        </div>
                                                        <div className='Product-View-Select-Tick'>
                                                            <IoCheckmarkOutline/>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                               }) 
                                    }
                                </div>
                            </div>
                        </div>
                     })
                    }
            <div className='Category-Head'><h2>Simalar Products</h2></div>
                <div className='Product-List'>
                {
                  similarProducts.length === 0 ? Loader : similarProducts.map((item,index)=>{
                    return <ProductDetail item={item} api={api} code={similarProducts[index].code} 
                            route={`/ProductView;${similarProducts[index].productid};${similarProducts[index].subcategoryname}`} key={index}
                            onClick={()=>{this.onDataForProductView(similarProducts[index].productid, similarProducts[index].subcategoryname)}}
                            addToFavorites={()=>{this.props.addToFavorites(similarProducts[index].productid)}} favoriteList={favoriteList}/>
                  })
                }
                </div>
            </div>
            <Footer/>
            </div>
      </div>
    )
  }
}

export default Product