import React from 'react';
import axios from 'axios';

class Location extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      noPincode: "",
      service: "",
      serviceData: false
    }
  }
  render() {
    const api = this.props.api;
    return (
    <div className='Popup-Location'>
        <button className='Button-Close-Location' onClick={this.props.onClose}>X</button>
    <div className='Popup-Inner-Location'>
        <div className='Location'>
            <div className='Location-Heading'>
                <h4>Check Delivery Location</h4>
            </div>
            <div className='Location-Form'>
                <form>
                  <div className="Location-Form-Control">
                    <input type="number" class="Phone" placeholder='Enter Your Pincode' onChange={(e)=>{
                      e.preventDefault();
                      if(e.target.value.length === 6){
                        this.setState({noPincode: e.target.value})
                        const data = axios.post( api ,{
                        query: "checkdeliverylocation", 
                        data: 
                        { pincode: e.target.value}
                        }).then( response => {
                             return response.data.data
                        })
                        data.then( value =>{
                          if(value !== null){
                            this.setState({service: value[0]});
                          }
                          this.setState({serviceData: true});
                        })
                      }
                        if(e.target.value.length === 0){
                          this.setState({serviceData: false})
                        }
                    }}/>
                  </div>
                  {
                  this.state.serviceData === true ? 
                  <div className='Location-Form-Control'> 
                      <div>
                        <p className={this.state.service !== undefined ? "Available" : "Not Available"}>
                          Service is {this.state.service !== undefined ? "Available" : "Not Available"} in &nbsp; 
                          {this.state.service !== undefined ? this.state.service.pincode : this.state.noPincode} area.</p>
                        {this.state.service !== undefined ? <p className='Cost'>Shipping cost is &#8377;{this.state.service.cost}.<br/> 
                        Orders Above &#8377;{this.state.service.minkartvalue} is available for free delivery.</p> : null}
                      </div>   
                    <p onClick={()=>{ 
                      this.setState({serviceData: false});
                      }}>Try Diffrent Pincode.</p>
                  </div> : null
                  }
                </form>
            </div>
        </div>
    </div>
    </div>
    )
  }
}

export default Location