import React, { Component } from 'react';
import Footer from './Footer';

class Checkout extends Component {4
    componentDidMount(){
        const responseHeaders = {
            query: "getActiveCart",
            data: {}
          }
          const jwt = localStorage.getItem('userDetails');
          const authorisation = {
            headers:{
              'Authorisation' : jwt
            }
          }
          this.props.onDataForCart(responseHeaders, authorisation);
    }
  render() {
    const cartProductList = this.props.cartList || [];
    const totalprice = cartProductList.map((item)=>{return JSON.parse(item?.totalprice)});
    const discount = cartProductList.map((item)=>{return JSON.parse(item?.discount)});
    var netPay = 0;
    var savings = 0;
    for (let i = 0; i < totalprice.length; i++) {
      netPay += totalprice[i];
    }
    for (let j = 0; j < discount.length; j++) {
      savings += discount[j];
    }
    const subTotal = netPay + savings;
    return (
      <div className='Content'>
        <div className='Store'>
            <div className='Category'>
                <div className='Category-Head'>
                    <h2>Checkout</h2>
                </div>
                <CheckoutTable subTotal={subTotal} netPay={netPay} savings={savings}/>
            </div>
            <Footer/>
        </div>
      </div>
    )
  }
}

export default Checkout;

class CheckoutTable extends Component {
  render() {
    const subTotal = this.props.subTotal;
    const savings = this.props.savings;
    const netpay = this.props.netPay;
    return (
      <div className='CartTable'>
        <div className='Cart-Total'>
          <div className='Cart-Count-Savings'>
            <span><b>Sub Total : </b>&#8377;&nbsp;{subTotal}</span>&nbsp;&nbsp;
            <span className='Cart-Savings'><b>Savings : </b>&#8377;&nbsp;{savings}</span>
            <span><b>Net Payable : </b>&#8377;&nbsp;{netpay}</span>
          </div>
        </div>
      </div>
    )
  }
}
