import React from 'react';
import axios from 'axios';
import SearchProductList from './SearchProductList';
import ClipLoader from "react-spinners/ClipLoader";
import Footer from './Footer';
import ProductDetail from './ProductDetail';

class OfferZone extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      override : {
        display: "block",
        margin: "26vh auto",
      }
    }
  }
  componentDidMount(){
    axios.post( this.props.api ,{
      query: "getOfferProductViewList",
      data: {
          startIndex : 0,
          pageSize : 24
      }
    }).then(response => {
      const offerProduct = response.data.data;
      const jsonObject = offerProduct.map(JSON.stringify);
      const uniqueSet = new Set(jsonObject);
      const offerProductData = Array.from(uniqueSet).map(JSON.parse);
      console.log(offerProductData)
      this.props.setStateOfOfferProductList(offerProductData);
    });
    const responseHeaders = {
      query: "getActiveCart",
      data: {}
    }
    const jwt = localStorage.getItem('userDetails');
    const authorisation = {
      headers:{
        'Authorisation' : jwt
      }
    }
    this.props.onDataForCart(responseHeaders, authorisation);
  }

  render() {
    const api = this.props.api;
    const offerProductList = this.props.offerProduct || [];
    const Loader = <ClipLoader cssOverride={this.state.override} size={25} color={"#123abc"} loading={true} speedMultiplier={1.5}
                    aria-label="Loading Spinner" data-testid="loader"/>
    const favoriteList = this.props.favoriteList || [];
    const searchProductList = this.props.searchProductList || [];

    return (
      <div className='Content'>
        <div className='Store'>
        <div className='Category'>
              <SearchProductList api={api} onDataForCart={this.props.onDataForCart} addToFavorites={this.props.addToFavorites}
                             addToCart={this.props.addToCart} favoriteList={favoriteList} searchProductList={searchProductList}/>
          <div className='Category-Head'>
            <h2>Offer Zone</h2>
          </div>
          <div className='Product-List'>
          {
            offerProductList.length === 0 ? Loader :
            offerProductList.map((item, index)=>{
              return  <ProductDetail item={item} api={api} code={offerProductList[index].code} key={index} 
                      route={`/ProductView;${offerProductList[index].productid};${offerProductList[index].subcategoryname}`}
                      addToFavorites={()=>{this.props.addToFavorites(offerProductList[index].productid)}} addToCart={this.props.addToCart}
                      favoriteList={favoriteList} productId={offerProductList[index].productid}/>
            })
          }
          </div>
        </div>
        <Footer/>
        </div>
      </div>
    )
  }
}

export default OfferZone