import React from 'react';
import axios from 'axios';
import SearchProductList from './SearchProductList';
import ProductDetail from './ProductDetail';
import ClipLoader from "react-spinners/ClipLoader";
import Footer from './Footer';

class ProductList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      productSelect : null,
      startIndex: 25,
      extraItems: true,
      override : {
        display: "block",
        margin: "26vh auto",
      }
    }
    this.onDataForProductListViewMore = this.onDataForProductListViewMore.bind(this);
  }
  componentDidMount(){
    const pathname = window.location.pathname.split(';');
    this.props.setStateOfType(decodeURI(pathname[2]));
    this.props.setStateOfPid(decodeURI(pathname[3]));
    this.props.setStateOfHeading(decodeURI(pathname[1]));
    this.props.onDataForProductList(decodeURI(pathname[2]), decodeURI(pathname[3]), 0);

      const responseHeaders = {
        query: "getActiveCart",
        data: {}
      }
      const jwt = localStorage.getItem('userDetails');
      const authorisation = {
        headers:{
          'Authorisation' : jwt
        }
      }
      this.props.onDataForCart(responseHeaders, authorisation);
  }

  onDataForProductListViewMore(){
    const pathname = window.location.pathname.split(';');
    const data = axios.post( this.props.api ,{
      query : "getProductViewList",
      data : {
        category: decodeURI(pathname[2]) , 
        pid: decodeURI(pathname[3]) , 
        startIndex : this.state.startIndex, 
        pageSize: 25, 
        searchKeyword: ""
      }
    }).then(response => {
      return response.data.data
    });
    data.then( value => {
      const productList = value;
      this.props.productList.push(productList);
      if(value.length === 0){
        this.setState({extraItems: false})
      }
    });
  }
  render() {

    const heading = this.props.heading || [];
    const api = this.props.api;
    var ProductList = this.props.productList?.flat(Infinity) || [];
    const favoriteList = this.props.favoriteList || [];
    const searchProductList = this.props.searchProductList || [];

    if(!this.props.productList){
      return <ClipLoader cssOverride={this.state.override} size={50} color={"#123abc"} loading={true} speedMultiplier={1.5}
              aria-label="Loading Spinner" data-testid="loader"/>
    }
    return (
      <div className='Content'>
        <div className='Store'>
        <div className='Category'>
                <SearchProductList api={api} onDataForCart={this.props.onDataForCart} addToFavorites={this.props.addToFavorites}
                             addToCart={this.props.addToCart} favoriteList={favoriteList} searchProductList={searchProductList}/>
          <div className='Category-Head'>
            <h2>{heading}</h2>
          </div>
          <div className='Product-List'>
          {
            ProductList !== [] ?
            ProductList.map((item, index)=>{
              return  <ProductDetail key={index} item={item} api={api} productId={ProductList[index].productid} code={ProductList[index].code} 
                       onDataForCart={this.props.onDataForCart}  addToFavorites={()=>{this.props.addToFavorites(ProductList[index].productid)}} 
                       favoriteList={favoriteList} 
                       route={`/ProductView;${ProductList[index].productid};${ProductList[index].subcategoryname}`} addToCart={this.props.addToCart}/>
            }) :
            <div className='Category-Head'>
              <h4>No Items found.</h4>
            </div>
          }
          </div>
          <div className='Category-Head'>
            {
              this.state.extraItems === true ? 
              <button onClick={()=>{
                this.onDataForProductListViewMore(this.setState({startIndex: this.state.startIndex + 25}));
              }}>View More</button> :
              <h4>No More Results found.</h4>
            }
          </div>
        </div>
        <Footer/>
        </div>
      </div>
    )
  }
}

export default ProductList