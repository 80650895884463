import './App.css';
import './Css/Header.css';
import './Css/Location.css'
import './Css/Content.css';
import './Css/Store.css';
import './Css/Dropdown.css'
import './Css/ProductList.css';
import './Css/ProductView.css';
import './Css/Cart.css';
import './Css/Favorite.css';
import './Css/Profile.css';
import './Css/Register.css';
import './Css/Footer.css';
import './Css/MyAccount.css'
import './Css/About.css';
import './Css/Contact.css';
import './Css/Terms.css';
import './Css/FAQ.css';
import './Css/Privacy.css';
import './Css/Refund.css';

import React from 'react';
import axios from 'axios';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Popup from 'reactjs-popup';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt_decode from "jwt-decode";

import { MdPersonOutline } from 'react-icons/md';
import { IoLocationSharp } from 'react-icons/io5';
import { FiMail } from 'react-icons/fi';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { IoMdArrowDropdown } from 'react-icons/io';
import { IoIosBasket } from 'react-icons/io';
import { CgSearch } from 'react-icons/cg';

import Home from "./Components/Home";
import Location  from './Components/Location';
import Profile from './Components/Profile';
import Register from './Components/Register';
import MyAccount from './Components/MyAccount';
import Dropdown from './Components/Dropdown';
import MyFavorite from './Components/MyFavorite';
import AllSubCategory from './Components/AllSubCategory';
import AllBrandList from './Components/AllBrandList';
import ProductList from './Components/ProductList';
import Product from './Components/Product';
import Checkout from './Components/Checkout'
import Cart from './Components/Cart';
import OfferZone from './Components/OfferZone';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';
import Terms from './Components/Terms';
import FAQ from './Components/FAQ';
import Privacy from './Components/Privacy'
import Refund from './Components/Refund'

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      api : process.env.REACT_APP_API_KEY,
      registerMessage: null,
      loginMessage: null,
      isLoggedIn: JSON.parse(localStorage.getItem('isLoggedIn')),
      popupSwitch : "",
      hover: false,
      type : "",
      pid : "",
      registered : false,
      registerCheck : "Individual",
      categoryList : [],
      subCategoryList : [],
      subCategoryListFull : [],
      createCartMessage : null,
      cartLength : 0,
      cartCountByProduct: 0,
      cartIdMessage: null,
      cartList: [],
      favoriteList : [],
      createFavoriteMessage : null,
      isFavoriteByIdMessage : null,
      keyWords : null,
      productList : null,
      searchProductList : null,
      activeIndex : 0,
      productView : null,
      similarProducts : null,
      offerProduct : null,
      brandList : [],
      brandListFull : [],
      heading : "",
      cartProductList : []
    }

    this.registerMessage = this.registerMessage.bind(this);
    this.loginMessage = this.loginMessage.bind(this);
    this.logout = this.logout.bind(this);
    this.setStateofPopupSwitch = this.setStateofPopupSwitch.bind(this);
    this.setStateOfType = this.setStateOfType.bind(this);
    this.setStateOfOfferProductList = this.setStateOfOfferProductList.bind(this);
    this.setStateOfSearchProductList = this.setStateOfSearchProductList.bind(this);
    this.setStateOfPid =  this.setStateOfPid.bind(this);
    this.setStateOfHeading = this.setStateOfHeading.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.createShippingAddress = this.createShippingAddress.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.clearCart = this.clearCart.bind(this);
    this.increaseCartItem = this.increaseCartItem.bind(this);
    this.decreaseCartItem = this.decreaseCartItem.bind(this);
    this.deleteCartItem = this.deleteCartItem.bind(this);
    this.addToFavorites = this.addToFavorites.bind(this);
    this.onDataForFavoriteList = this.onDataForFavoriteList.bind(this);
    this.onDataForProductList = this.onDataForProductList.bind(this);
    this.setStateOfActiveIndex = this.setStateOfActiveIndex.bind(this);
    this.onDataForProductView = this.onDataForProductView.bind(this);
    this.onDataForSimilarProducts = this.onDataForSimilarProducts.bind(this);
    this.onDataForCart = this.onDataForCart.bind(this);

  }

  registerMessage(newMessage){
    this.setState({registerMessage: newMessage})
    if(newMessage.Message === "Registration successfull."){
      toast.success(newMessage.Message)
    }
    if(newMessage.Message !== "Registration successfull."){
      toast.error(newMessage.Message)
    }
  }

  loginMessage(newMessage){
    this.setState({loginMessage: newMessage});
    if(newMessage.Message === "Login success."){
      toast.success("Welcome " + newMessage.data.username);
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('userDetails', newMessage.jwt);
      window.location.reload();
    }
    if(newMessage.Message !== "Login success."){
      toast.error(newMessage.Message);
      localStorage.setItem('isLoggedIn', 'false');
      localStorage.setItem('userDetails','null')
    }
  }

  logout(){
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.setItem('userDetails','null');
    toast.success('Logged out Successfully');
    window.setTimeout( function() {
      window.location.reload();
    }, 1000);
  }

  componentDidMount(){
    axios.post( this.state.api ,{
        query : "getCategoryTop10",
        data : {}
    }).then(response => {
      this.setState({categoryList: response.data.data})
    });

    axios.post( this.state.api ,{
        query : "getSubcategoryTop10",
        data : {}
    }).then(response => {
      this.setState({subCategoryList: response.data.data})
    });

    axios.post( this.state.api ,{
        query : "getActiveSubcategory",
        data : {}
    }).then(response => {
      this.setState({subCategoryListFull: response.data.data})
    });

    axios.post( this.state.api ,{
      query : "getBrandTop10",
      data : {}
    }).then(response => {
    this.setState({brandList: response.data.data})
    });

    axios.post( this.state.api ,{
      query : "getActiveBrand",
      data : {}
    }).then(response => {
    this.setState({brandListFull: response.data.data})
    });

    const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
    if(isLoggedIn){
      const responseHeaders = {
        query: "getMyFavourite",
        data: {}
      }
      const responseHeaders1 = {
        query: "getActiveCart",
        data: {}
      }
      const jwt = localStorage.getItem('userDetails');
      const authorisation = {
        headers:{
          'Authorisation' : jwt
        }
      }
      this.onDataForFavoriteList(responseHeaders, authorisation);
      this.onDataForCart(responseHeaders1, authorisation);
    }

  }

  setStateofPopupSwitch(newPopup){
    this.setState({popupSwitch: newPopup})
  }

  setStateOfSearchProductList(newKeyword){
    if(newKeyword === null){
      this.setState({searchProductList: null});
    }
    if(newKeyword !== null){
    const data = axios.post( this.state.api ,{
      query : "getProductSearch",
      data :     {
        keyword: newKeyword , 
        startIndex: 0, 
        pageSize: 24
    }
    }).then(response =>{
      return response.data.data
    });
    data.then( value =>{
      const productList = value;
      this.setState({searchProductList: productList});
    });
    const data2 = axios.post(this.state.api ,{
      query: "createKeywords",
      data: {
          searchtext: newKeyword
      }
    }).then( response =>{
      return response.data
    });
    data2.then(value =>{
      this.setState({keyWords: value});
    });
    }
  }
  
  setStateOfOfferProductList(newSetState){
    this.setState({offerProduct: newSetState})
  }
  
  setStateOfType(newType){
    this.setState({type: newType});
  }

  setStateOfPid(newPid){
    this.setState({pid: newPid})
  }

  setStateOfHeading(newHeading){
    this.setState({heading: newHeading});
    localStorage.setItem('Heading', JSON.stringify(newHeading))
  }

  setStateOfActiveIndex(newIndex){
    this.setState({activeIndex: newIndex})
  }

  onDataForProductList(type, pid){
    this.setState({productList: null});
    const data = axios.post( this.state.api ,{
      query : "getProductViewList",
      data : {
        category: type , 
        pid: pid , 
        startIndex : 0, 
        pageSize: 25, 
        searchKeyword: ""
      }
    }).then(response => {
      return response.data.data
    });
    data.then( value => {
      const productList = value;
      this.setState({productList: productList});
    });
  }

  onDataForProductView(newProductId){
    this.setState({productView: null});
    const productData = axios.post( this.state.api ,{
      query : "getProductDetailsById",
      data : { productid : newProductId }
    }).then(response =>{
      return response.data.data
    })
    productData.then( value => {
      this.setState({productView: value});
    });
  }

  onDataForSimilarProducts(newSubCat, newProductId){
    this.setState({similarProducts: null})
    const similarData = axios.post( this.state.api ,{
      query : "getSimilarItems",
      data : { subcat: newSubCat , productid : newProductId }
    }).then(response =>{
      return response.data.data
    });
    similarData.then( value => {
      this.setState({similarProducts: value})
    })
  }

  updateProfile(newUserName,newUserPhone,newUserEmail,newUserProfilePic){
    const jwt = localStorage.getItem('userDetails');
    const userDetails = jwt_decode(jwt);
    const userName = newUserName === null ? userDetails.data.username : newUserName 
    const userPhone = newUserPhone === null ? userDetails.data.phone : newUserPhone
    const userEmail = newUserEmail === null ? userDetails.data.email : newUserEmail
    const userProfilePic = newUserProfilePic === null ? null : newUserProfilePic
    const api = this.state.api;
    const responseHeader = {
      query: "updateProfile",
      data: {
          username: userName,
          _email: userEmail,
          _phone: userPhone,
          profileimage: userProfilePic
      }
    }
    const responseHeader2 = {
        query: "reloadUser",
        data: {}
    }
    const authorisation = {
    headers:{
      'Authorisation' : jwt
    }
    }
    const data = axios.post(api,responseHeader,authorisation).then(response =>{
      return response.data
    })
    data.then(value =>{
      if(value.Message === "Profile updated successfully."){
        toast.success(value.Message)
      }
    })
    const data2 = axios.post(api,responseHeader2,authorisation).then(response =>{
      return response.data
    })
    data2.then(value =>{
      localStorage.setItem('userDetails', value.jwt);
    })
  }

  createShippingAddress(addressDetails){
    const jwt = localStorage.getItem('userDetails');
    const api = this.state.api;
    const responseHeader = {
      query: "createShippingaddress",
      data: {
          firstaddress: addressDetails.userFirstAddress,
          secondaddress: addressDetails.userSecondAddress,
          landmark: addressDetails.userLandmark,
          city: addressDetails.userCity,
          state: "29-KARNATAKA",
          pincode: addressDetails.userPincode,
          name: addressDetails.userName,
          phone: addressDetails.userPhone,
          status: true,
          pid: 0
      }
    }
    const authorisation = {
      headers:{
        'Authorisation' : jwt
      }
    }
    const data = axios.post(api, responseHeader, authorisation).then(response =>{
      return response.data
    })
    data.then(value =>{
      toast.success(value.Message)
    })
  }

  updatePassword(passwordDetails){
    if(passwordDetails.oldPassword === null || passwordDetails.newPassword === null || passwordDetails.confirmNewPassword === null){
      toast.warn("Please Fill all the Required Fields")
    }
    if(passwordDetails.oldPassword !== null || passwordDetails.newPassword !== null || passwordDetails.confirmNewPassword !== null){
      const jwt = localStorage.getItem('userDetails');
      const api = this.state.api;
      const authorisation = {
      headers:{
        'Authorisation' : jwt
      }
      }
      const responseHeader = {
        query: "changeUserPassword",
        data: {
          oldpassword: passwordDetails.oldPassword,
          newpassword: passwordDetails.newPassword,
          confirmpassword: passwordDetails.confirmNewPassword
        }
      }
      const responseHeader2 = {
        query: "reloadUser",
        data: {}
      }
      const data = axios.post(api , responseHeader, authorisation).then(response =>{
        return response.data
      })
      data.then(value =>{
      if(value.Message === "Confirm password is not match."){
        toast.error(value.Message)
      }
      if(value.Message !== "Confirm password is not match."){
        if(value.data[0].Message === "Old password is wrong."){
          toast.error(value.data[0].Message)
        }
        if(value.data[0].Message !== "Old password is wrong."){
          toast.success(value.Message)
        }
      }
    })
    const data2 = axios.post(api,responseHeader2,authorisation).then(response =>{
      return response.data
    })
    data2.then(value =>{
      localStorage.setItem('userDetails', value.jwt);
      window.location.reload();
    })
    } 
  }

  addToCart(newId, newQty, newUnit){
    const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
    if(!isLoggedIn){
      toast.error("Please Login to Add items to Basket");
    }
    if(isLoggedIn){
      const jwt = localStorage.getItem('userDetails');
      const api = this.state.api 
      const responseHeader1 = {
        query: "createCart",
        data: {
            productid: newId,
            unit: newUnit,
            qty: newQty
        }
      }
      const responseHeader2 = {
        query: "getCartCount",
        data: {}
      }
      const responseHeader3 = {
        query: "getCartCountByProduct",
        data: {
            productid: newId,
            unit: newUnit
        }
      }
      const responseHeader4 = {
        query: "isCartById",
        data: {
            productid: newId,
            unit: newUnit
        }
      }
      const authorisation = {
        headers:{
          'Authorisation' : jwt
        }
      }
      const data1 = axios.post(api , responseHeader1, authorisation).then(response => {
        return response.data
      });
      data1.then( value => {
        this.setState({createCartMessage: value.Message});
      });

      const data2 = axios.post(api, responseHeader2, authorisation).then(response => {
        return response.data
      });
      data2.then( value => {
        this.setState({cartLength: value.data[0].qty});
      });

      const data3 = axios.post(api, responseHeader3, authorisation).then(response =>{
        return response.data
      });
      data3.then( value => {
        this.setState({cartCountByProduct: value.data[0].qty})
      })

      const data4 = axios.post(api, responseHeader4, authorisation).then(response => {
        return response.data
      });
      data4.then(value => {
        this.setState({cartIdMessage: value.Message});
        toast.success(value.Message)
      })
      const response = {
        query: "getActiveCart",
        data: {}
      }
      this.onDataForCart(response, authorisation);
    }
  }

  clearCart(){
    const responseHeader = {
      query: "deleteCartByuserId",
      data: {}
    }
    const jwt = localStorage.getItem('userDetails');
    const authorisation = {
      headers:{
        'Authorisation' : jwt
      }
    }
    const data = axios.post( this.state.api, responseHeader, authorisation).then(response =>{
      return response.data
    })
    data.then( value => {
      toast.success(value.Message);
    });
    const responseHeaders1 = {
      query: "getActiveCart",
      data: {}
    }
    this.onDataForCart(responseHeaders1,authorisation);
  }

  onDataForCart(responseHeaders, authorisation){
    this.setState({cartList: null});
    const data = axios.post( this.state.api , responseHeaders, authorisation).then(response => {
      return response.data.data
    });
    data.then( value => {
      this.setState({cartList: value});
      const qty = value.map((item)=>{return JSON.parse(item?.qty)});
      var cartQty = 0;
      for (let i = 0; i < value.length; i++) {
        cartQty += qty[i];
      }
      this.setState({cartLength: cartQty})
    });
  }

  increaseCartItem(newId, newUnit, newQty, maxOrderQty){
    if(newQty > maxOrderQty){
      toast.warn("You reached the purchase limit for this item.");
    }
    if(newQty < maxOrderQty){
      const responseHeader = {
        query: "increaseCartCount",
        data: {
            productid: newId,
            unit: newUnit
        }
      }
      const header = {
        query: "getActiveCart",
        data: {}
      }
      const jwt = localStorage.getItem('userDetails');
      const authorisation = {
        headers:{
          'Authorisation' : jwt
        }
      }
      const data = axios.post(this.state.api,responseHeader, authorisation).then(response =>{
        return response.data
      });
      data.then(value => {
        toast.success(value.Message);
      });
      this.onDataForCart(header,authorisation);
    }
  }

  decreaseCartItem(newId, newUnit, newQty){
    if(newQty > 0){
      const responseHeader = {
        query: "decreaseCartCount",
        data: {
            productid: newId,
            unit: newUnit
        }
      }
      const header = {
        query: "getActiveCart",
        data: {}
      }
      const jwt = localStorage.getItem('userDetails');
      const authorisation = {
        headers:{
          'Authorisation' : jwt
        }
      }
      const data = axios.post(this.state.api,responseHeader, authorisation).then(response =>{
        return response.data
      });
      data.then(value => {
        toast.success(value.Message);
      });
      this.onDataForCart(header,authorisation);
    }
  }

  deleteCartItem(newId, newUnit){
    const responseHeader = {
      query: "deleteCartItem",
      data: {
          productid: newId,
          unit: newUnit
      }
    }
    const header = {
      query: "getActiveCart",
      data: {}
    }
    const jwt = localStorage.getItem('userDetails');
    const authorisation = {
      headers:{
        'Authorisation' : jwt
      }
    }
    const data = axios.post(this.state.api,responseHeader, authorisation).then(response =>{
      return response.data
    });
    data.then(value => {
      toast.success(value.Message);
    });
    this.onDataForCart(header,authorisation);
  }

  addToFavorites(newId){
    const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
    if(!isLoggedIn){
      toast.error("Please Login to Add items to Favorites");
    }
    if(isLoggedIn){
      const userDetails = JSON.parse(localStorage.getItem('isLoggedIn')) === true ? jwt_decode(localStorage.getItem('userDetails')) : null
      const jwt = localStorage.getItem('userDetails');
      const responseHeaders1 = {
        query: "createFavourite",
        data: {
          userid: userDetails.data.pid,
          productid: newId
        }
      }
      const responseHeaders2 = {
        query: "isFavouriteById",
        data: {
          userid: userDetails.data.pid,
          productid: newId
        }
      }
      const authorisation = {
        headers:{
          'Authorisation' : jwt
        }
      }

      const data1 = axios.post(this.state.api, responseHeaders1, authorisation).then(response => {
        return response.data
      });
      data1.then( value => {
        this.setState({createFavoriteMessage: value.Message});
        toast.success(value.Message);
      });

      const data2 = axios.post(this.state.api, responseHeaders2, authorisation).then(response => {
        return response.data
      })
      data2.then( value => {
        this.setState({isFavoriteByIdMessage: value.Message});
      });
      const responseHeaders = {
        query: "getMyFavourite",
        data: {}
      }
      this.onDataForFavoriteList(responseHeaders,authorisation);
    }
  }

  onDataForFavoriteList(responseHeaders, authorisation){
    this.setState({favoriteList: null});
    const data = axios.post( this.state.api , responseHeaders, authorisation).then(response => {
      return response.data.data
    });
    data.then( value => {
      this.setState({favoriteList: value});
    });
  }

  render() {
    const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));

    const home = <Home categoryList={this.state.categoryList} subCategoryList={this.state.subCategoryList}
                  AllSubCategory={this.state.subCategoryListFull} BrandList={this.state.brandList}
                  api={this.state.api} searchProductList={this.state.searchProductList} 
                  addToCart={this.addToCart} addToFavorites={this.addToFavorites} favoriteList={this.state.favoriteList}/>

    const myAccount = <MyAccount onDataForCart={this.onDataForCart} updateProfile={this.updateProfile} api={this.state.api}
                        createShippingAddress={this.createShippingAddress} updatePassword={this.updatePassword}/>
                    
    const allSubCategory = <AllSubCategory api={this.state.api} AllSubCategory={this.state.subCategoryListFull} searchProductList={this.state.searchProductList}
                            setStateOfType={this.setStateOfType} setStateOfPid={this.setStateOfPid} addToCart={this.addToCart} onDataForCart={this.onDataForCart}
                            setStateOfHeading={this.setStateOfHeading} onDataForProductList={this.onDataForProductList} 
                            favoriteList={this.state.favoriteList} addToFavorites={this.addToFavorites}/>

    const allBrand = <AllBrandList api={this.state.api} AllBrandList={this.state.brandListFull} searchProductList={this.state.searchProductList}
                      setStateOfType={this.setStateOfType} setStateOfPid={this.setStateOfPid} addToCart={this.addToCart} onDataForCart={this.onDataForCart}
                      setStateOfHeading={this.setStateOfHeading} onDataForProductList={this.onDataForProductList} 
                      favoriteList={this.state.favoriteList} addToFavorites={this.addToFavorites}/>

    const productList = <ProductList productList={this.state.productList} type={this.state.type} pid={this.state.pid} 
                        api={this.state.api} setStateOfProductId={this.setStateOfProductId} activeIndex={this.state.activeIndex}
                        setStateOfSubCat={this.setStateOfSubCat} heading={this.state.heading} cartLength={this.state.cartLength} searchProductList={this.state.searchProductList}
                        setStateOfType={this.setStateOfType} setStateOfPid={this.setStateOfPid} setStateOfHeading={this.setStateOfHeading} 
                        setStateOfActiveIndex={this.setStateOfActiveIndex} onDataForProductList={this.onDataForProductList} addToCart={this.addToCart}
                        favoriteList={this.state.favoriteList} onDataForFavoriteList={this.onDataForFavoriteList} 
                        onDataForCart={this.onDataForCart} addToFavorites={this.addToFavorites}/>

    const product = <Product productView={this.state.productView} similarProducts={this.state.similarProducts} api={this.state.api} 
                    activeIndex={this.state.activeIndex} setStateOfActiveIndex={this.setStateOfActiveIndex} setStateOfProductId={this.setStateOfProductId}
                    setStateOfSubCat={this.setStateOfSubCat} onDataForProductView={this.onDataForProductView} addToCart={this.addToCart}
                    onDataForSimilarProducts={this.onDataForSimilarProducts} favoriteList={this.state.favoriteList} 
                    onDataForCart={this.onDataForCart} addToFavorites={this.addToFavorites} searchProductList={this.state.searchProductList}/>

    const cart = <Cart api={this.state.api} cartList={this.state.cartList} cartLength={this.state.cartLength} onDataForCart={this.onDataForCart}
                  addToCart={this.addToCart} deleteCartItem={this.deleteCartItem} increaseCartItem={this.increaseCartItem} searchProductList={this.state.searchProductList}
                  decreaseCartItem={this.decreaseCartItem} clearCart={this.clearCart} addToFavorites={this.addToFavorites} 
                   favoriteList={this.state.favoriteList}/>

    const myFav = <MyFavorite api={this.state.api} favoriteList={this.state.favoriteList} onDataForFavoriteList={this.onDataForFavoriteList} 
                  onDataForCart={this.onDataForCart} addToCart={this.addToCart} addToFavorites={this.addToFavorites}/>

    const offerZone = <OfferZone offerProduct={this.state.offerProduct} api={this.state.api} searchProductList={this.state.searchProductList}
                      setStateOfOfferProductList={this.setStateOfOfferProductList} addToCart={this.addToCart}
                      favoriteList={this.state.favoriteList} addToFavorites={this.addToFavorites} onDataForCart={this.onDataForCart}/>

    const checkOut = <Checkout cartList={this.state.cartList} onDataForCart={this.onDataForCart}/>

    const aboutUs = <AboutUs api={this.state.api} searchProductList={this.state.searchProductList} addToCart={this.addToCart}
                            favoriteList={this.state.favoriteList} addToFavorites={this.addToFavorites} onDataForCart={this.onDataForCart}/>

    const contactUs = <ContactUs api={this.state.api} searchProductList={this.state.searchProductList} addToCart={this.addToCart}
                            favoriteList={this.state.favoriteList} addToFavorites={this.addToFavorites} onDataForCart={this.onDataForCart}/>

    const terms = <Terms/>

    const faq = <FAQ/>

    const privacy = <Privacy/>

    const refund = <Refund/>
    return (
      <div className='App'>
      <BrowserRouter>
      <Header api={this.state.api} popupSwitch={this.state.popupSwitch} registerCheck={this.state.registerCheck} 
              onIndividual={this.onIndividual} onWholesaler={this.onWholesaler} logout={this.logout}
              cartLength={this.state.cartLength} registerMessage={this.registerMessage} loginMessage={this.loginMessage}
              setStateOfIsLoggedIn = {this.setStateOfIsLoggedIn} setStateOfPopupSwitch={this.setStateofPopupSwitch}
              setStateOfSearchProductList={this.setStateOfSearchProductList}
              setStateOfType={this.setStateOfType} setStateOfPid={this.setStateOfPid} 
              setStateOfHeading={this.setStateOfHeading} onDataForProductList={this.onDataForProductList}/>
       <Routes>
            <Route index element={home} />
            <Route path='MyAccount' element={isLoggedIn ? myAccount : home} />
            <Route path='MyBasket' element={cart} />
            <Route path='MyFavorites' element={myFav} />
            <Route path="AllSubCategory" element={allSubCategory}/>
            <Route path="AllSubCategory/ProductList;:categoryname;:category;:pid" element={productList}/>
            <Route path='AllSubCategory/ProductList;:categoryname;:category;:pid' element={product}/>
            <Route path="AllBrand" element={allBrand} />
            <Route path="AllBrand/ProductList;:categoryname;:category;:pid" element={productList}/>
            <Route path='AllBrand/ProductList;:categoryname;:category;:pid' element={product}/>
            <Route path="ProductList;:categoryname;:category;:pid" element={productList} />
            <Route path='ProductList;:categoryname;:category;:pid' element={product}/>
            <Route path='ProductView;:id;:subcategory' element={product}/>
            <Route path="Checkout" element={checkOut}/>
            <Route path="Offer" element={offerZone} />
            <Route path="AboutUs" element={aboutUs} />
            <Route path="ContactUs" element={contactUs} />
            <Route path="Terms" element={terms} />
            <Route path="FAQ" element={faq} />
            <Route path="PrivacyPolicy" element={privacy} />
            <Route path="RefundPolicy" element={refund} />
      </Routes>
    </BrowserRouter>
      <ToastContainer position="top-right" autoClose={1500} hideProgressBar={false} newestOnTop={false} closeOnClick
        rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
      </div>
    )
  }
}

export default App

class Header extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      searchValue: null
    }
  }
  render(){
    const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
    const userDetails = JSON.parse(localStorage.getItem('isLoggedIn')) === true ? jwt_decode(localStorage.getItem('userDetails')) : null
    const list = [
                  {name:"My Account",path:"/MyAccount"},
                  {name:"My Basket",path:"/MyBasket"},
                  {name:"My Favorites",path:"/MyFavorites"},
                  {name:"Contact Us",path:"/ContactUs"}
                ];
    const logout = this.props.logout;
    const cartLength = this.props.cartLength
    
   return (
     <div className='Header'>
        <div className='Main-Header'>
          <div className='logo-div'>
            <Link to={"/"}>
              <img src='https://www.veekaart.com/assets/img/logo-header.png' alt='logo' className='logo'/>
            </Link>
          </div>
          <div>
          <div className='TopHeader'>
              <div>
                <MdPersonOutline/>&nbsp;&nbsp;
                {
                  isLoggedIn  ? 
                  <>
                  <Popup trigger={<span>{userDetails.data.username}&nbsp;&nbsp;</span>} closeOnDocumentClick={false}>
                    {close =>
                    (
                      <div onClick={close} className={"Popup-Dropdown-Login"}>
                        <div className='Popup-Inner-Dropdown-Login'>
                          <div className='DropDown'>
                            {list.map((item,index)=>{
                                return <div className='Dropdown-List' key={index}>
                                        <Link to={item.path}>
                                          {item.name}
                                        </Link>
                                      </div>
                            })}
                            <div className='Dropdown-List' onClick={logout}><Link to={"/"}>Log Out</Link></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                  </> 
                  :
                  <>
                  <Popup trigger={<span>LogIn / </span>} closeOnDocumentClick={false}>
                    {close =>
                    (
                    <Profile onClose={close} api={this.props.api}
                    loginMessage={this.props.loginMessage} 
                    setStateOfIsLoggedIn={this.props.setStateOfIsLoggedIn}/>
                    )
                    }
                  </Popup>
                  <Popup trigger={<span>Sign Up&nbsp;&nbsp;</span>} closeOnDocumentClick={false}>
                    {close =>
                    (
                    <Register onClose={close} api={this.props.api}
                    registerMessage={this.props.registerMessage}/>
                    )
                    }
                  </Popup>
                  </>             
                }
                {/*<IoMdArrowDropdown/>*/}
              </div>
              <Popup trigger={ 
              <div>
                <IoLocationSharp/>&nbsp;&nbsp;Check Delivery Location <IoMdArrowDropdown/>
              </div>} closeOnDocumentClick={false}>
                {close =>
                (
                  <Location onClose={close} api={this.props.api}/>
                )}
              </Popup>
              <div>
                <FiMail/>&nbsp;&nbsp;veekaart.onlinestore@gmail.com
              </div>
          </div>
          <div className='MiddleHeader'>
            <div className='Input-Section'>
              <input type="text" placeholder="Search for Products..." className='Search' onChange={(e)=>{
                if((e.target.value.length > 2) === true){
                  this.props.setStateOfSearchProductList(e.target.value);
                  let value = e.target.value;
                  this.setState({searchValue: value});
                }
                if(e.target.value.length === 0){
                  this.setState({searchValue: null});
                  this.props.setStateOfSearchProductList(null);
                }
                }}/>
              <button onClick={()=>{this.props.setStateOfSearchProductList(this.state.searchValue)}}><CgSearch/></button>
            </div>
            <div className='Icons'>
            <div className='Icon'>
             <Link to={"/MyBasket"}>
              <div>
                <IoIosBasket/>
              </div>
              <div>
              <span>My Basket <br/>{cartLength} Items</span>
             </div>
             </Link>
           </div>
       </div>
   </div>
          </div>
      </div>
   <div className='BottomHeader'>
       <div className='Dropdown-Container'>
          <Popup trigger={<div><button className='Dropdown-Button'>SHOP BY CATEGORY</button></div>}>
            {
              close => {
                return <Dropdown onClose={close} setStateOfType={this.props.setStateOfType} setStateOfPid={this.props.setStateOfPid} 
                setStateOfHeading={this.props.setStateOfHeading} onDataForProductList={this.props.onDataForProductList}/>
              }
            }
          </Popup>  
         <div>
          <Link to={"Offer"}>
             <button className='Dropdown-Button2'><MdOutlineLocalOffer/>OFFERS</button>
          </Link>
         </div>
       </div>
   </div>
   <Outlet />
   </div>
   )
  }
 }
