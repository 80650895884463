import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends Component {
  render() {
    const list1 = [{name: "About Us", path: "AboutUs"},
                   {name: "Contact Us", path: "ContactUs"},
                   {name: "Privacy Policy",path: "PrivacyPolicy"},
                   {name: "Refund Policy", path: "RefundPolicy"},
                   {name: "FAQ",path: "FAQ"},
                   {name: "Terms and Condition", path: "Terms"}]
    return (
      <div className='Footer'>
        <div className='Footer-Container'>
            <div className='Logo'>
                <img src='https://www.veekaart.com/assets/img/logo-header.png' alt='logo' className='Logo-Image'/>
            </div>
        </div>
        <div className='Footer-Container'>
            <ul>
                {list1.map((item,index)=>{
                    return <Link to={"/"+ item.path} key={index}><li className='Footer-List'>{item.name}</li></Link>
                })}
            </ul>
        </div>
      </div>
    )
  }
}