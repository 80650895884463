import React from 'react';
import axios from 'axios';
import Footer from './Footer';

class Privacy extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      description1: null,
      description2: null
    }
    this.getPrivacyPolicy = this.getPrivacyPolicy.bind(this);
  }

  getPrivacyPolicy(){
    const api = process.env.REACT_APP_API_KEY
    const responseHeader = {
      query: "getPrivacyPolicy",
      data: {}
    }
    const data = axios.post(api, responseHeader).then(response =>{
      return response.data
    });
    data.then(value =>{
      this.setState({description1: value.data[0].privacypolicy});
      this.setState({description2: value.data[1].privacypolicy});
    })
  }
  componentDidMount(){
    this.getPrivacyPolicy();
  }

  render() {
    return (
      <div className='Content'>
        <div className='Store'>
          <div className='Privacy'>
                <h2>Privacy Policy</h2>
            <div className='Privacy-form'>
              <div className="form-control">
                <p>
                  {this.state.description1}
                </p>
                <p>
                  {this.state.description2}
                </p>
              </div>
            </div>
        </div>
        <Footer/>
        </div>
      </div>
    )
  }
}

export default Privacy