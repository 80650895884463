import React from 'react'
import { Link, Outlet } from 'react-router-dom';
import SearchProductList from './SearchProductList';
import ClipLoader from "react-spinners/ClipLoader";
import Footer from './Footer';

class AllBrandList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      override : {
        display: "block",
        margin: "25vh auto",
      }
    }
  }
  render() {
    const api = this.props.api;
    const allBrandList = this.props.AllBrandList;
    const Loader = <ClipLoader cssOverride={this.state.override} size={50} color={"#123abc"} loading={true} speedMultiplier={1.5}
                    aria-label="Loading Spinner" data-testid="loader"/>
    const searchProductList = this.props.searchProductList || [];
    const favoriteList = this.props.favoriteList || [];

    return (
      <div className='Content'>
        <div className='Store'>
        <div className='Brand'>
            <SearchProductList api={api} onDataForCart={this.props.onDataForCart} addToFavorites={this.props.addToFavorites}
                addToCart={this.props.addToCart} favoriteList={favoriteList} searchProductList={searchProductList}/>
          <div className='Brand-Head'>
            <h2>Brand</h2>
            <p>{allBrandList.length} found</p>
          </div>
          <div className='Brand-Full-List'>
            {
              allBrandList.length === 0 ? Loader : 
              allBrandList.map((item, index)=>{
                return <Link to={`ProductList;${item.brandname};brand;${item.pid}`} key={index}>
                        <div className='List-Brand'>
                          <div className='Brand-Img'>
                            <img 
                            src={item.image !== null ? 
                                'https://api.veekaart.com/assets/'+ JSON.parse(item.image)[0] : 
                                'https://www.veekaart.com/assets/img/noimage.png'} 
                            alt={item.brandname}/>
                          </div>
                          <div className='Brand-Name'>
                            <b>{item.brandname}</b>
                          </div>
                        </div>
                       </Link>
              })
            }
          </div>
        </div>
        <Footer/>
        </div>
        <Outlet/>
      </div>
    )
  }
}

export default AllBrandList