import React, { Component } from 'react';
import axios from 'axios';
import Footer from './Footer';
import {AiFillCamera} from 'react-icons/ai';
import {BsFileEarmarkPersonFill} from 'react-icons/bs';
import {ImAddressBook} from 'react-icons/im';
import {CgInbox} from 'react-icons/cg'
import {BsFillPencilFill} from 'react-icons/bs';
import {GrPowerReset} from 'react-icons/gr';
import {IoMdAddCircleOutline} from 'react-icons/io';
import jwt_decode from "jwt-decode";
import { toast } from 'react-toastify';

class MyAccount extends Component {
  constructor(props){
    super(props);
    this.state={
      activeIndex: 0,
      profilePic: null,
      userShippingAddress: null
    }
    this.setStateOfActiveIndex = this.setStateOfActiveIndex.bind(this);
  }
  setStateOfActiveIndex(newIndex){
    this.setState({activeIndex: newIndex});
  }
  componentDidMount(){
    const jwt = localStorage.getItem('userDetails');
    const file = jwt_decode(jwt).data.profileimage;
    const authorisation = {
      headers:{
        'Authorisation' : jwt
      }
    }
    const header = {
      query: "profileImage",
      data: {
          file: file
      }
    }
    const header2 = {
      query: "getShippingaddressByUserId",
      data: {}
    }
    const data = axios.post(this.props.api, header ,authorisation).then(response =>{
      return response.data
    });
    data.then(value =>{
      this.setState({profilePic: value.data})
    });
    const data2 = axios.post(this.props.api, header2, authorisation).then(response =>{
      return response.data
    });
    data2.then(value =>{
      this.setState({userShippingAddress: value.data})
    })
  }
  render() {
    const userDetails = JSON.parse(localStorage.getItem('isLoggedIn')) === true ? jwt_decode(localStorage.getItem('userDetails')) : null;
    const image = this.state.profilePic === null ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" :
                  this.state.profilePic
    const sideNav = [{
                      title:"Personal Information", 
                      svg:<BsFileEarmarkPersonFill/>,
                      component:<PersonalInformation userDetails={userDetails} image={image} setStateOfActiveIndex={this.setStateOfActiveIndex}/>
                    },
                     {
                      title:"Shipping Address", 
                      svg:<ImAddressBook/>,
                      component:<ShippingAddress createShippingAddress={this.props.createShippingAddress}/>
                    },
                    {
                      title:"My Orders",
                      svg:<CgInbox/>,
                      component: <MyOrders/>
                    },
                     {
                      title:"Edit Profile", 
                      svg:<BsFillPencilFill/>,
                      component:<EditProfile image={image} userDetails={userDetails} updateProfile={this.props.updateProfile}/>
                    },
                     {
                      title:"Reset Password", 
                      svg:<GrPowerReset/>,
                      component:<ResetPassword updatePassword={this.props.updatePassword}/>
                    }]
    const activeIndex = this.state.activeIndex;
    const activeRightSideContent = sideNav[activeIndex].component;

    return (
      <div className='Content'>
        <div className='Store'>
          <div className='Account'>
            <div className='Left-Account-Content'>
              <div className='Account-Name'>
                <div className='Account-Image'>
                  <img src={"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt="Display"/>
                </div>
                <div className='Account-Details'>
                  <p>Hello,<br/><b>{userDetails.data.username}</b></p>
                </div>
              </div>
              <div className='Account-Side-Navigation'>
                {sideNav.map((item,index)=>{
                  return <div className={activeIndex === index ? 'Side-Nav-Heading-Active': 'Side-Nav-Heading' }key={index}
                          onClick={()=>{this.setState({activeIndex: index})}}>
                            {item.svg}&nbsp;&nbsp;{item.title}
                          </div>
                })}
              </div>
            </div>
            <div className='Right-Account-Content'>
              {activeRightSideContent}
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    )
  }
}

export default MyAccount

class PersonalInformation extends Component {
  render() {
    const userDetails = this.props.userDetails;
    const image = this.props.image;

    return (
      <div className='Right-Account-Content-Details'>
        <div className='Account-Heading'>
          <h3>Personal Information</h3>
        </div>
        <div className='Profile-Image'>
          <img src={image} alt="Display"/>
        </div>
        <div className='Profile-Details'>
          <h4>Contact Information:</h4>
          <div className='Details'>
            <b>Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><span>{userDetails.data.username}</span><br/>
            <b>Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><span>{userDetails.data.email}</span><br/>
            <b>Phone&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><span>{userDetails.data.phone}</span>
          </div>
          <h4>Shipping Address:</h4>
          <div className='Add-Icon'>
            <button onClick={()=>{this.props.setStateOfActiveIndex(1)}}><IoMdAddCircleOutline/></button>
          </div>
        </div>
      </div>
    )
  }
}

class ShippingAddress extends Component {
  constructor(props){
    super(props);
    this.state = {
      addressDetails: {
        userName: null,
        userPhone: null,
        userFirstAddress: null,
        userSecondAddress: null,
        userLandmark: null,
        userCity: null,
        userState: null,
        userPincode: null,
        userStatus: true
      }
    }
    this.handleName = this.handleName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleFirstAddress = this.handleFirstAddress.bind(this);
    this.handleSecondAddress = this.handleSecondAddress.bind(this);
    this.handleLandmark = this.handleLandmark.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handlePincode = this.handlePincode.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleName(e){
    let userData = this.state.addressDetails;
    userData.userName = e.target.value;
    this.setState({addressDetails: userData})
  }
  handlePhone(e){
    let userData = this.state.addressDetails;
    userData.userPhone = e.target.value;
    this.setState({addressDetails: userData})
  }
  handleFirstAddress(e){
    let userData = this.state.addressDetails;
    userData.userFirstAddress = e.target.value;
    this.setState({addressDetails: userData})
  }
  handleSecondAddress(e){
    let userData = this.state.addressDetails;
    userData.userSecondAddress = e.target.value;
    this.setState({addressDetails: userData})
  }
  handleLandmark(e){
    let userData = this.state.addressDetails;
    userData.userLandmark = e.target.value;
    this.setState({addressDetails: userData})
  }
  handleCity(e){
    let userData = this.state.addressDetails;
    userData.userCity = e.target.value;
    this.setState({addressDetails: userData})
  }
  handleState(e){
    let userData = this.state.addressDetails;
    userData.userState = e.target.value;
    this.setState({addressDetails: userData})
  }
  handlePincode(e){
    let userData = this.state.addressDetails;
    userData.userPincode = e.target.value;
    this.setState({addressDetails: userData})
  }
  handleStatus(boolean){
    let userData = this.state.addressDetails;
    userData.userStatus = boolean;
    this.setState({addressDetails: userData})
  }
  handleSubmit(){
    const addressDetails = this.state.addressDetails
    if(addressDetails.userName === null || addressDetails.userPhone === null || addressDetails.userFirstAddress === null ||
      addressDetails.userSecondAddress === null || addressDetails.userLandmark === null || addressDetails.userCity === null ||
      addressDetails.userState === null){
        toast.error("Kindly Fill all the Required Fields")
      }
    else{
      this.props.createShippingAddress(addressDetails);
    }
  }
  render() {
    return (
      <div className='Right-Account-Content-Details'>
        <div className='Shipping-Address-Container'>
        <div className='Account-Heading'>
          <h3>Shipping Address</h3>
        </div>
        <div className='Input-Fields'>
          <div>
            <label>Name *</label>
            <input type='text' onChange={this.handleName}/>
          </div>
          <div>
            <label>Phone *</label>
            <input type='Number' onChange={this.handlePhone}/>
          </div>
          <div>
            <label>Address Line 1 *</label>
            <input type='text' onChange={this.handleFirstAddress}/>
          </div>
          <div>
            <label>Address Line 2 </label>
            <input type='text' onChange={this.handleSecondAddress}/>
          </div>
          <div>
            <label>Landmark *</label>
            <input type='text' onChange={this.handleLandmark}/>
          </div>
          <div>
            <label>City *</label>
            <input type='text' onChange={this.handleCity}/>
          </div>
          <div>
            <label>State *</label>
            <select onChange={this.handleState}>
              <option value={"Karnataka"}>Karnataka</option>
            </select>
          </div>
          <div>
            <label>Pincode *</label>
            <input type='text' onChange={this.handlePincode}/>
          </div>
          <div>
            <label>Status</label>
            <input type='checkbox' defaultChecked onChange={(e)=>{this.handleStatus(e.target.checked)}}/>
          </div>
          <div><b>* These fields are required.</b></div>
        </div>
        <div className='Save-Button'>
            <button onClick={()=>{this.handleSubmit()}}>SAVE</button>
        </div>
        </div>
      </div>
    )
  }
}

class MyOrders extends Component {
  render() {
    return (
      <div className='Right-Account-Content-Details'>
        <div className='Account-Heading'>
          <h3>My Orders</h3>
        </div>
      </div>
    )
  }
}
class EditProfile extends Component {
  constructor(props){
    super(props);
    this.state = {
      userDetails: {
        userName: null,
        userPhone: null,
        userEmail: null,
        userProfilePic: null
      }
    }
    this.handleName = this.handleName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.getBase64 = this.getBase64.bind(this);
    this.handleProfilePic = this.handleProfilePic.bind(this);
    this.triggerInputFile = this.triggerInputFile.bind(this);
  }
  handleName(e){
    let userData = this.state.userDetails;
    userData.userName = e.target.value;
    this.setState({userDetails: userData})
  }
  handlePhone(e){
    let userData = this.state.userDetails;
    userData.userPhone = e.target.value;
    this.setState({userDetails: userData})
  }
  handleEmail(e){
    let userData = this.state.userDetails;
    userData.userEmail = e.target.value;
    this.setState({userDetails: userData})
  }
  getBase64(file){
    return new Promise(resolve => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  handleProfilePic(e){
    let userData = this.state.userDetails;
    this.getBase64(e.target.files[0]).then(result => {
      userData.userProfilePic = result;
      this.setState({userDetails: userData});
    })
    .catch(err => {
      console.log(err);
    });
    this.setState({userDetails: userData});
  }
  triggerInputFile(){
    this.fileInput.click()
  }
  render() {
    const image = this.props.image;
    const userDetails = this.props.userDetails;
    return (
      <div className='Right-Account-Content-Details'>
        <div className='Account-Heading'>
          <h3>Edit Profile</h3>
        </div>
        <div className='Profile-Image'>
          <img src={this.state.userDetails.userProfilePic === null ? image : this.state.userDetails.userProfilePic} alt="Display"/>
          <label htmlFor='Image-File'><button onClick={()=>{this.triggerInputFile()}}><AiFillCamera/></button></label>
          <input type="file" ref={fileInput => this.fileInput = fileInput} classname="Image-File" accept="image/*;capture=camera"
          onChange={this.handleProfilePic}/>
        </div>
        <div className='Profile-Details-Edit-Profile'>
          <h4>Basic Information:</h4>
          <div>
            <label>Name:</label>&nbsp;&nbsp;
            <input type={"text"} defaultValue={userDetails.data.username} onChange={this.handleName}/>
          </div>
          <div>
            <label>Email:</label>&nbsp;&nbsp;
            <input type={"text"} defaultValue={userDetails.data.email} onChange={this.handleEmail}/>
          </div>
          <div>
            <label>Phone:</label>&nbsp;&nbsp;
            <input type={"number"} defaultValue={userDetails.data.phone} onChange={this.handlePhone}/>
          </div>
        </div>
        <div className='Save-Button'>
            <button onClick={
              ()=>{
                this.props.updateProfile(this.state.userDetails.userName,
                this.state.userDetails.userPhone,
                this.state.userDetails.userEmail,
                this.state.userDetails.userProfilePic)}}>
                  Update
            </button>
        </div>
      </div>
    )
  }
}

class ResetPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      passwordDetails : {
        oldPassword : null,
        newPassword : null,
        confirmNewPassword : null
      }
    }
    this.handleOldPassword = this.handleOldPassword.bind(this);
    this.handleNewPassword = this.handleNewPassword.bind(this);
    this.handleConfirmNewPassword = this.handleConfirmNewPassword.bind(this);
  }
  handleOldPassword(e){
    let passwordDetails = this.state.passwordDetails;
    passwordDetails.oldPassword = e.target.value
    this.setState({passwordDetails: passwordDetails})
  }
  handleNewPassword(e){
    let passwordDetails = this.state.passwordDetails;
    passwordDetails.newPassword = e.target.value
    this.setState({passwordDetails: passwordDetails})
  }
  handleConfirmNewPassword(e){
    let passwordDetails = this.state.passwordDetails;
    passwordDetails.confirmNewPassword = e.target.value
    this.setState({passwordDetails: passwordDetails})
  }
  render() {
    return (
      <div className='Right-Account-Content-Details'>
        <div className='Account-Heading'>
          <h3>Reset Password</h3>
        </div>
        <div className='Reset-Password-Input-Fields'>
          <div>
            <label>Old Password</label>
            <input type="password" className="Old-Password" onChange={this.handleOldPassword}/>
          </div>
          <div>
            <label>New Password</label>
            <input type="password" className="New-Password" onChange={this.handleNewPassword}/>
          </div>
          <div>
            <label>Confirm New Password</label>
            <input type="password" className="New-Password" onChange={this.handleConfirmNewPassword}/>
          </div>
        </div>
        <div className='Update-Button'>
            <button onClick={()=>{this.props.updatePassword(this.state.passwordDetails)}}>UPDATE</button>
        </div>
      </div>
    )
  }
}