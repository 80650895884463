import React, { Component } from 'react';
import ProductDetail from './ProductDetail';

class SearchProductList extends Component {
  render() {
    const searchProductList = this.props.searchProductList;
    const api = this.props.api;
    const favoriteList = this.props.favoriteList;
    return (
        <div className='Product-List'>
            {
              searchProductList.map((item, index)=>{
                return <ProductDetail item={item} api={api} code={searchProductList[index].code} onDataForCart={this.props.onDataForCart} key={index} 
                        route={`/ProductView;${searchProductList[index].productid};${searchProductList[index].subcategoryname}`}
                        addToFavorites={()=>{this.props.addToFavorites(searchProductList[index].productid)}} addToCart={this.props.addToCart}
                        favoriteList={favoriteList} productId={searchProductList[index].productid}/>
              })
            }
          </div>
    )
  }
}

export default SearchProductList