import React from 'react';
import axios from 'axios';
import { AiOutlineHeart } from 'react-icons/ai';
import { AiFillHeart } from 'react-icons/ai';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { IoIosBasket } from 'react-icons/io';
import { Link, Outlet } from 'react-router-dom';

class ProductDetail extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            productSelect : null,
            activeIndex : 0,
            productImage: null,
            productQty: 1,
            popupShow: false
        }
    }
  render() {

    const item = this.props.item;
    const api = this.props.api;
    const code = this.props.code;
    const favoriteList = this.props.favoriteList;
    const productId = this.props.productId;
    const route = this.props.route;
    const productSelect = this.state.productSelect || [];
    const activeIndex = this.state.activeIndex;
    const productQty = this.state.productQty;
    const productRate = [productSelect[activeIndex]] || [];
    const image = this.state.productImage || null;
    const index =  favoriteList.findIndex(item => item.code === productSelect[activeIndex]?.productcode);

    return (
        <div className="List-Product" onLoad={()=>{
            const data = axios.post( api ,{
            query : "getProductunitpriceByProductCode",
            data : { "productcode" : code},
            }).then( response => {
              return response.data.data
            })
            data.then( value =>{
                this.setState({productSelect: null})
                this.setState({productSelect: value});
                const image = value[activeIndex].productimage === undefined ? null : value[activeIndex].productimage;
                this.setState({productImage: null});
                this.setState({productImage: image})
            })
            this.setState({productRate: productSelect[activeIndex]})
            }}>
            <div className='Product-Offer'>
              <div className='Offer'>{productRate[0]?.offer}% OFF <MdOutlineLocalOffer/></div>
              <FavoriteIcon onClick={this.props.addToFavorites} boolean={productSelect[activeIndex]?.productcode.includes(favoriteList[index]?.code)}/>
            </div>
            <Link to={route} onClick={this.props.onClick}>
            <div className='Product-Img'>
              <img src={image === null ? "https://www.veekaart.com/assets/img/noimage.png" : "https://api.veekaart.com/assets/" + JSON.parse(image)[0]} 
              alt={item.productname}/>
            </div>
            <div className='Product-Details'>
              <span className='Product-Brand'>{item.brandname}</span><br/>
              <span><b>{item.productname}</b></span><br/>
              <span>{item.description}</span><br/>
            </div>
            </Link>
            <div className='Product-Select'>
              <div onClick={()=>{this.setState({popupShow: !this.state.popupShow})}}>
                <span>{productSelect[activeIndex]?.unit} - Rs {productSelect[activeIndex]?.sellingprice}</span>
              </div>
              {this.state.popupShow === true ? 
                <div className='Popup-Unit'>
                  <div className='Popup-Inner-Unit'>
                    {productSelect?.map((item, index)=>{
                      return <div key={index} className="Unit-Value" onClick={()=>{
                        this.setState({activeIndex: index});
                        this.setState({popupShow: false});
                      }}><span>{item?.unit} - Rs {item?.sellingprice}</span></div>
                    })}
                  </div>
                </div> 
                : <></>}
            </div>
            <div className='Product-Rate'>
                <div className='Product-Rate-Mrp'>
                    <span>MRP:</span> <span className='Mrp'>Rs {productRate[0]?.mrp} </span>&nbsp;
                    <span className='Selling'>Rs {productRate[0]?.sellingprice}</span>
                </div>
                <div className='Quantity'>
                  {
                    productRate[0]?.stock !== '0' ? 
                    <>
                    <div className='Quantity-Input'>
                        <span>Qty</span>
                        <input type={"number"} defaultValue={1} min={1} max={productRate[0]?.maxorderqty} onChange={(e)=>{
                          this.setState({productQty: e.target.value})
                        }}/>
                    </div>
                    <div className='Quantity-Cart'>
                        <button className='Cart-Button' onClick={()=>{
                          this.props.addToCart(productId,productQty,productSelect[activeIndex]?.unit);
                          }}>ADD <IoIosBasket/></button>
                    </div>
                    </> :
                    <div className='Out-Of-Stock'>
                      Out of Stock
                    </div>
                  }
                </div>
            </div>
            <Outlet/>
          </div>
    )
  }
}

export default ProductDetail

class FavoriteIcon extends React.Component {
  render(){
    return(
      <div className='Favorite-Icon' onClick={this.props.onClick}>
        {this.props.boolean  ? <AiFillHeart/> : <AiOutlineHeart/>}
      </div>
    )
  }
}