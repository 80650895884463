import axios from 'axios';
import React from 'react';
import Footer from './Footer';

class Terms extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        description: null,
        index: null,
    }
    this.getTerms = this.getTerms.bind(this);
    this.getActiveIndex = this.getActiveIndex.bind(this);
  }

  getTerms(){
    const api = process.env.REACT_APP_API_KEY
    const responseHeader = {
      query: "getActiveTerms",
      data: {}
    }
    const data = axios.post(api, responseHeader).then(response =>{
      return response.data
    });
    data.then(value =>{
      this.setState({description: value.data})
    })
  }

  getActiveIndex(newIndex){
    if(this.state.index === newIndex){
      this.setState({index: null});
    }
    else{
      this.setState({index: newIndex})
    }
  }

  componentDidMount(){
    this.getTerms();
    this.setState({index: null})
  }

  render() {
    const description = this.state.description || [];
    const activeIndex = this.state.index;
    return (
        <div className='Content'>
          <div className='Store'>
            <div className='Terms'>
              <h2>Terms & Conditions</h2>
            <div className='Terms-form'>
              <div className="form-control">
                {
                  description.map((item,index)=>{
                    return  <div className='Pop-Up' key={index} onClick={()=>{this.getActiveIndex(index)}}>
                              <p>{item.title}</p>
                                <div className= {activeIndex === index ? "form-control" : 'form-control-unview'}>
                                  <p dangerouslySetInnerHTML={{__html: item.description}}>
                                  </p>
                                </div>
                            </div>
                  })
                }
              </div>
            </div>
            </div>
            <Footer/>
          </div>
        </div>
    )
  }
}

export default Terms