import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import SearchProductList from './SearchProductList';
import ClipLoader from "react-spinners/ClipLoader";
import Footer from './Footer';

class AllSubCategory extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      override : {
        display: "block",
        margin: "25vh auto",
      }
    }
  }
  render() {
    const api = this.props.api;
    const subCategoryListFull = this.props.AllSubCategory;
    const Loader = <ClipLoader cssOverride={this.state.override} size={50} color={"#123abc"} loading={true} speedMultiplier={1.5}
                    aria-label="Loading Spinner" data-testid="loader"/>
    const searchProductList = this.props.searchProductList || [];
    const favoriteList = this.props.favoriteList || [];

    return (
      <div className='Content'>
        <div className='Store'>
        <div className='Sub-Category'>
          <SearchProductList api={api} onDataForCart={this.props.onDataForCart} addToFavorites={this.props.addToFavorites}
                addToCart={this.props.addToCart} favoriteList={favoriteList} searchProductList={searchProductList}/>
          <div className='Sub-Category-Head'>
            <h2>Subcategories</h2>
            <p>{subCategoryListFull.length} found</p>
          </div>
          <div className='Sub-Category-Full-List'>
            {
              subCategoryListFull.length === 0 ? Loader : 
              subCategoryListFull.map((item, index)=>{
                return <Link to={`ProductList;${item.subcategoryname};subcat;${item.pid}`} key={index}>
                        <div className='List-Sub-Category'>
                          <div className='Sub-Category-Img'>
                            <img src={'https://api.veekaart.com/assets/'+ item.image.slice(2,item.image.length-2)} alt={item.subcategoryname}/>
                          </div>
                          <div className='Sub-Category-Name'>
                            <b>{item.subcategoryname}</b>
                          </div>
                        </div>
                       </Link>
              })
            }
          </div>
        </div>
        <Footer/>
        </div>
        <Outlet/>
      </div>
    )
  }
}

export default AllSubCategory