import React from 'react';
import SearchProductList from './SearchProductList';
import Footer from './Footer';
import axios from 'axios';

class AboutUs extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      description: null
    }
    this.getAboutUs = this.getAboutUs.bind(this);
  }

  getAboutUs(){
    const api = this.props.api;
    const responseHeader = {
      query: "getAboutus",
      data: {}
    }
    const data = axios.post(api, responseHeader).then(response =>{
      return response.data
    });
    data.then(value =>{
      this.setState({description: value.data[0].aboutus});
    });
  }

  componentDidMount(){
    this.getAboutUs();
  }

  render() {
    const api = this.props.api;
    const aboutUs = this.state.description
    const searchProductList = this.props.searchProductList || [];
    const favoriteList = this.props.favoriteList || [];
    return (
      <div className='Content'>
        <div className='Store'>
            <div className='About'>
                  <SearchProductList api={api} onDataForCart={this.props.onDataForCart} addToFavorites={this.props.addToFavorites}
                    addToCart={this.props.addToCart} favoriteList={favoriteList} searchProductList={searchProductList}/>
                <h2>About Us</h2>
            <div className='About-form'>
              <div className="form-control">
                <p>
                  {aboutUs}
                </p>
              </div>
            </div>
        </div>
        <Footer/>
        </div>
      </div>
    )
  }
}

export default AboutUs