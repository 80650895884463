import React, { Component } from 'react'
import { Link, Outlet } from 'react-router-dom';

class Dropdown extends Component {
    constructor(props){
        super(props);
        this.state = {
            list : [{Name:"Foodgrains, Oil & Masala",pid:1},
                    {Name:"Beauty & Hygiene",pid:2},
                    {Name:"Snacks & Branded Foods",pid:3},
                    {Name:"Bevereges",pid:4},
                    {Name:"Cleansing & Household",pid:5},
                    {Name:"Baby Care",pid:7}] 
        }
    }
  render() {
    return (
      <div onClick={this.props.onClose} className={"Popup-Dropdown"}>
        <div className='Popup-Inner-Dropdown'>
            <div className='DropDown'>
              {this.state.list.map((item,index)=>{
                return <div className='Dropdown-List' key={index} onClick={()=>{
                    window.location.reload();
                }}>
                            <Link to={`ProductList;${item.Name};category;${item.pid}`}>{item.Name}</Link>
                        </div>
              })}
            </div>
        </div>
        <Outlet/>
      </div>
    )
  }
}

export default Dropdown