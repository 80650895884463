import React, { Component } from 'react';
import Footer from '../Components/Footer';
import { Outlet, Link } from "react-router-dom";
import SearchProductList from './SearchProductList';


class Home extends Component {
  render() {
    const api = this.props.api;
    const searchProductList = this.props.searchProductList || [];
    const categoryList = this.props.categoryList;
    const subCategoryList = this.props.subCategoryList;
    const allSubCategory = this.props.AllSubCategory;
    const brandList = this.props.BrandList;
    const favoriteList = this.props.favoriteList || [];

    return (
      <div className='Content'>
        <div className='Store'>
        <div className='Category'>
              <SearchProductList api={api} onDataForCart={this.props.onDataForCart} addToFavorites={this.props.addToFavorites}
                             addToCart={this.props.addToCart} favoriteList={favoriteList} searchProductList={searchProductList}/>
          <div className='Category-Head'>
            <h2>Categories</h2>
            <p>{categoryList.length} found</p>
          </div>
          <div className='Category-List'>
            {
            categoryList.map((item, index)=>{
              return  <div className='List-Category' key={index}>
                        <Link to={`ProductList;${item.categoryname};category;${item.pid}`}>
                        <div className='Category-Img'>
                            <img src={'https://api.veekaart.com/assets/'+ item.image.slice(2,item.image.length-2)} alt={item.categoryname}/>
                        </div>
                        <div className='Category-Name'>
                            <p>{item.categoryname}</p>
                        </div>
                        </Link>
                      </div> 
            })
        }
          </div>
        </div>
        <div className='Sub-Category'>
          <div className='Sub-Category-Head'>
            <h2>Subcategories</h2>
            <p>{allSubCategory.length} found</p>
          </div>
          <div className='Sub-Category-List'>
            <div className='ViewAll'>
              <Link to="AllSubCategory">
                  <button>Show More</button>
              </Link>
            </div>
            {
              subCategoryList.map((item, index)=>{
                return   <div className='List-Sub-Category' key={index}>
                        <Link to={`ProductList;${item.subcategoryname};subcat;${item.pid}`}>
                            <div className='Sub-Category-Img'>
                              <img src={'https://api.veekaart.com/assets/'+ item.image.slice(2,item.image.length-2)} alt={item.subcategoryname}/>
                            </div>
                            <div className='Sub-Category-Name'>
                              <p>{item.subcategoryname}</p>
                            </div>
                            </Link>
                          </div>
              })
            }
          </div>
        </div>
        <div className='Brand'>
          <div className='Brand-Head'>
            <h2>Brand</h2>
            <p>195 found</p>
          </div>
          <div className='Brand-List'>
            <div className='ViewAll'>
              <Link to="AllBrand"><button>Show More</button></Link>
            </div>
            {
              brandList.map((item, index)=>{
                return  <div className='List-Brand' key={index}>
                        <Link to={`ProductList;${item.brandname};brand;${item.pid}`}>
                          <div className='Brand-Img'>
                            <img src={'https://api.veekaart.com/assets/'+ item.image.slice(2,item.image.length-2)} alt={item.brandname}/>
                          </div>
                          <div className='Brand-Name'>
                            <p>{item.brandname}</p>
                          </div>
                          </Link>
                        </div>
              })
            }
          </div>
        </div>
        <Footer/>
        </div>
        <Outlet/>
      </div>
    )
  }
}

export default Home