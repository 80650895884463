import React from 'react';
import SearchProductList from './SearchProductList';
import Footer from './Footer';

class ContactUs extends React.Component {
  render() {
    const api = this.props.api;
    const searchProductList = this.props.searchProductList || [];
    const favoriteList = this.props.favoriteList || [];
    return (
        <div className='Content'>
            <div className='Store'>
            <div className='Contact'>
                <SearchProductList api={api} onDataForCart={this.props.onDataForCart} addToFavorites={this.props.addToFavorites}
                    addToCart={this.props.addToCart} favoriteList={favoriteList} searchProductList={searchProductList}/>
                <h2>Contact Us</h2>
            <form>
                <div className='Contact-Div'>
                    <div className="form-control">
                        <label>Name:</label>
                        <input type="text" class="Name"/>
                    </div>
                    <div className="form-control">
                        <label>Phone:</label>
                        <input type="text" class="Phone" />
                    </div>
                </div>
                <div className='Contact-Div'>
                    <div className="form-control">
                        <label>E-Mail:</label>
                        <input type="mail" class="Mail" />
                    </div>
                    <div className="form-control">
                        <label>Please specify your need:</label>
                        <select>
                            <option value="Select Category">Select Category</option>
                            <option value="Request Product">Request Product</option>
                            <option value="Request Order Status">Request order status</option>
                            <option value="Request copy of an invoice">Request copy of an invoice</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>
                <div className="form-control">
                    <label>Message:</label>
                    <input type="text" class="Message" />
                </div>
                <div className='Submit'>
                    <button>Send Message</button>
                </div>
                <div className='Address-Info'>
                    <div className="form-control">
                        <b>Address:</b>
                        <p>RAMNATH TRADERS<br/>#454SE,29th Main, 2nd Cross,<br/>Nisarga Layout,Jigani,<br/>Bangalore-560083.</p>
                    </div>
                    <div className="form-control">
                        <p><b>E-Mail:</b>&nbsp;veekaart.onlinestore@gmail.com</p>
                        <p><b>Phone:</b>&nbsp;+91 7892853796</p>
                    </div>
                </div>
            </form>
            </div>
            <Footer/>
            </div>
        </div>
    )
  }
}

export default ContactUs