import React, { Component } from 'react';
import ProductDetail from './ProductDetail';
import { AiOutlineHeart } from 'react-icons/ai';
import { Link, Outlet } from 'react-router-dom';
import Footer from './Footer';

class MyFavorite extends Component {
  componentDidMount(){
    const responseHeaders = {
      query: "getMyFavourite",
      data: {}
    }
    const responseHeader = {
      query: "getActiveCart",
      data: {}
    }
    const jwt = localStorage.getItem('userDetails');
    const authorisation = {
      headers:{
        'Authorisation' : jwt
      }
    }
    this.props.onDataForFavoriteList(responseHeaders, authorisation);
    this.props.onDataForCart(responseHeader, authorisation);
  }

  render() {

    const api = this.props.api;
    const favoritesList = this.props.favoriteList || [];

    return (
      <div className='Content'>
        <div className='Store'>
          <div className='Category'>
            <div className='Category-Head'>
              <h2>My Favorites</h2>
            </div>
            {
              favoritesList.length === 0 ? <EmptyFav/> : <Favorites api={api} favoriteList={favoritesList} 
              addToFavorites={this.props.addToFavorites}/>
            }
          </div>
          <Footer/>
        </div>
      </div>
    )
  }
}

export default MyFavorite

class EmptyFav extends React.Component {
  render() {
    return (
      <div className='EmptyCart'>
        <div className='Basket-Icon'><AiOutlineHeart/></div>
        <h4>Let's fill the empty Favorites</h4>
        <Link to={"/"}>
          <button>Continue Shopping</button>
        </Link>
        <Outlet/>
      </div>
    )
  }
}

class Favorites extends React.Component {
  render() {
    const api = this.props.api
    const favoritesList = this.props.favoriteList || [];
    return (
      <div className='Product-List'>
      {
        favoritesList.map((item, index)=>{
          return <ProductDetail item={item} api={api} code={favoritesList[index].code} onDataForCart={this.props.onDataForCart} key={index} 
                  route={`/ProductView;${favoritesList[index].productid};${favoritesList[index].subcategoryname}`}
                  favoriteList={favoritesList}
                  addToFavorites={()=>{
                    this.props.addToFavorites(favoritesList[index].productid);
                  }} addToCart={this.props.addToCart}/>
        })
      }
    </div>
    )
  }
}